<template>
  <div>
    <v-app-bar
      app
      light
      flat
      color="white"
      class="appBar"
      style="border-top: 2px solid #d3352c !important"
    >
      <v-toolbar-title
        v-if="widthNow > 550"
        @click="$router.push('/')"
        :style="{ marginLeft: marginLeft }"
      >
        <img
          class="dkLogo"
          :src="require('../../assets/dompetkilat.png')"
          alt="DK Logo"
        />
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  created() {
    if (window.innerWidth <= 1007) {
      this.fontSize = "40px";
    } else {
      this.fontSize = "60px";
    }
    window.addEventListener("resize", this.myEventHandler);
  },

  mounted() {
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  data: () => ({
    //
    marginLeft: `${window.innerWidth / 9}px`,
    marginRight: `${window.innerWidth / 9}px`,
    heightNow: window.innerHeight,
    widthNow: window.innerWidth,
    windowSize: {
      x: 0,
      y: 0,
    },
    fontSize: "60px",
    widthSize: window.innerWidth - 100,
  }),

  methods: {
    scroll() {
      document
        .querySelector("#skema")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    myEventHandler() {
      if (window.innerWidth <= 1007) {
        this.fontSize = "40px";
      } else {
        this.fontSize = "60px";
      }
      this.heightNow = window.innerHeight;
      this.widthNow = window.innerWidth;
      this.marginLeft = `${window.innerWidth / 9}px`;
      this.marginRight = `${window.innerWidth / 9}px`;

      this.widthSize = window.innerWidth - 100;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style>
.search.v-input .v-input__slot {
  border-radius: 5px;
  height: 29px;
  font-size: 14px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
.navbar.v-tab {
  font-size: 16px;
}
.navbar {
  font-size: 16px;
}
.no-background::before {
  background-color: transparent !important;
}
.appBar {
  height: 90px !important;
  padding-top: 1%;
}
.dkLogo {
  width: 251px;
  padding-right: 60px;
  cursor: pointer;
}
.toolbarTitle {
  font-family: "sora";
  font-weight: 1000;
  font-size: 25px;
  letter-spacing: -0.07rem;
  padding: 1px;
  position: relative;
  padding-right: 60px;
}
.ojk {
  width: 65px;
  height: 24px;
  margin-right: 10px;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: right;
}
.tkbContainer {
  font-family: "sora";
  font-weight: 1000;
  font-size: 25px;
  padding: 14px;
  margin-bottom: 11px;
  letter-spacing: -0.07rem;
  position: relative;
}
.tkbText {
  width: 73.5px;
  height: 16.5px;
  object-fit: contain;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.tkbPercentage {
  width: 49px;
  height: 16.5px;
  object-fit: contain;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #3bb54a;
}
.navButton {
  font-weight: 1000 !important;
  font-size: 14px !important;
  margin-top: 5px !important;
}
.btnSpacer {
  margin-right: 20px;
}
.borderNavButton {
  margin-top: 2px;
  height: 45%;
  border-bottom-style: solid;
  border-bottom-color: #d3352c;
}
@media screen and (max-width: 1342px) {
  .appBar {
    height: 80px !important;
    padding-top: 1%;
  }
  .dkLogo {
    width: 201px;
    padding-right: 40px;
    cursor: pointer;
  }
  .toolbarTitle {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    letter-spacing: -0.07rem;
    padding: 1px;
    position: relative;
    padding-right: 60px;
  }
  .ojk {
    width: 65px;
    height: 24px;
    margin-right: 10px;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
  }
  .tkbContainer {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    padding: 14px;
    margin-bottom: 11px;
    letter-spacing: -0.07rem;
    position: relative;
  }
  .tkbText {
    width: 73.5px;
    height: 16.5px;
    object-fit: contain;
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
  .tkbPercentage {
    width: 49px;
    height: 16.5px;
    object-fit: contain;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #3bb54a;
  }
  .navButton {
    font-weight: 1000 !important;
    font-size: 14px !important;
    margin-top: 10px !important;
  }
  .btnSpacer {
    margin-right: 20px;
  }
  .borderNavButton {
    margin-top: -2px;
    height: 50%;
    border-bottom-style: solid;
    border-bottom-color: #d3352c;
  }
}
@media screen and (max-width: 1142px) {
  .appBar {
    height: 70px !important;
    padding-top: 1%;
  }
  .dkLogo {
    width: 151px;
    padding-right: 10px;
    cursor: pointer;
  }
  .toolbarTitle {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    letter-spacing: -0.07rem;
    padding: 1px;
    position: relative;
    padding-right: 60px;
  }
  .ojk {
    width: 65px;
    height: 24px;
    margin-left: 30px;
    margin-right: 10px;
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
  }
  .tkbContainer {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    padding: 14px;
    margin-bottom: 11px;
    letter-spacing: -0.07rem;
    position: relative;
  }
  .tkbText {
    width: 63.5px;
    height: 16.5px;
    object-fit: contain;
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
  .tkbPercentage {
    width: 39px;
    height: 16.5px;
    object-fit: contain;
    font-size: 11px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #3bb54a;
  }
  .navButton {
    font-weight: 1000 !important;
    font-size: 10px !important;
    margin-top: 10px !important;
  }
  .btnSpacer {
    margin-right: 10px;
  }
  .borderNavButton {
    margin-top: -2px;
    height: 50%;
    border-bottom-style: solid;
    border-bottom-color: #d3352c;
  }
}
@media screen and (max-width: 942px) {
  .appBar {
    height: 70px !important;
    padding-top: 1%;
  }
  .dkLogo {
    width: 151px;
    padding-right: 10px;
    cursor: pointer;
  }
  .toolbarTitle {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    letter-spacing: -0.07rem;
    padding: 1px;
    position: relative;
    padding-right: 60px;
  }
  .ojk {
    width: 45px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: Inter;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
  }
  .tkbContainer {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    padding: 14px;
    margin-bottom: 11px;
    letter-spacing: -0.07rem;
    position: relative;
  }
  .tkbText {
    width: 63.5px;
    height: 16.5px;
    object-fit: contain;
    font-size: 8px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
  .tkbPercentage {
    width: 39px;
    height: 16.5px;
    object-fit: contain;
    font-size: 10px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #3bb54a;
  }
  .navButton {
    font-weight: 1000 !important;
    font-size: 9px !important;
    margin-top: 10px !important;
  }
  .btnSpacer {
    margin-right: 10px;
  }
  .borderNavButton {
    margin-top: -5px;
    height: 54%;
    border-bottom-style: solid;
    border-bottom-color: #d3352c;
  }
}
@media screen and (max-width: 842px) {
  .appBar {
    height: 70px !important;
    padding-top: 1%;
  }
  .dkLogo {
    width: 101px;
    padding-right: 10px;
    cursor: pointer;
  }
  .toolbarTitle {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    letter-spacing: -0.07rem;
    padding: 1px;
    position: relative;
    padding-right: 60px;
  }
  .ojk {
    width: 45px;
    height: 24px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: Inter;
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: right;
  }
  .tkbContainer {
    font-family: "sora";
    font-weight: 1000;
    font-size: 25px;
    padding: 14px;
    margin-bottom: 11px;
    letter-spacing: -0.07rem;
    position: relative;
  }
  .tkbText {
    width: 63.5px;
    height: 16.5px;
    object-fit: contain;
    font-size: 8px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
  .tkbPercentage {
    width: 39px;
    height: 16.5px;
    object-fit: contain;
    font-size: 10px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #3bb54a;
  }
  .navButton {
    font-weight: 1000 !important;
    font-size: 8px !important;
    margin-top: 10px !important;
  }
  .btnSpacer {
    margin-right: 10px;
  }
  .borderNavButton {
    margin-top: -5px;
    height: 54%;
    border-bottom-style: solid;
    border-bottom-color: #d3352c;
  }
}
/* .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover{
border-width: 1px;
border-style: solid;
border-color: #6fbd44;
} */
</style>
