<template>
  <div>
    <div id="contact" class="footerContent">
      <div class="bg-contact">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <ul class="list">
                <li>
                  <img
                    style="max-width: 142px"
                    :src="require('../../assets/dompetkilat.png')"
                    alt="John"
                  />
                  <div class="address">
                    <div>
                      PT Indo Fin Tek
                    </div>
                    <div>Gedung Townhouse, Lantai 8A</div>
                    <div>Jl. Sungai Gerong No.1A Jakarta Pusat, 10230.</div>
                  </div>
                </li>
                <li>
                  <div class="address-1">
                    <span>(021) 3193-4699</span>
                  </div>
                </li>
                <li>
                  <div class="address-1">
                    <span>hello@dompetkilat.co.id</span>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-md-3">
              <ul class="list">
                <v-row no-gutters>
                  <v-col>
                    <div class="midlle">
                      <div class="footerTitle">
                        <span>Tergabung dalam</span>
                      </div>
                      <div class="afpiContainer">
                        <img
                          class="afpiLogo"
                          :src="require('../../assets/BottomNav/Logo-AFPI.png')"
                          alt
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="midlle">
                      <div class="footerTitle">
                        <span>Berizin dan diawasi oleh</span>
                      </div>
                      <img
                        class="ojkLogo"
                        :src="require('../../assets/BottomNav/Logo-OJK.png')"
                        alt
                      />
                    </div>
                  </v-col>
                </v-row>
              </ul>
            </div>

            <div class="col-md-2">
              <ul class="list">
                <li>
                  <div class="footerTitle">
                    <span>Sosial media</span>
                  </div>
                </li>
              </ul>
              <ul class="media-list">
                <li>
                  <a href="https://twitter.com/dompetkilat_id" target="_blank">
                    <img
                      class="socialIcon"
                      :src="require('../../assets/BottomNav/Twitter.png')"
                      alt
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/DompetKilat.ID/"
                    target="_blank"
                  >
                    <img
                      class="socialIcon"
                      :src="require('../../assets/BottomNav/Facebook.png')"
                      alt
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/DompetKilat_ID/"
                    target="_blank"
                  >
                    <img
                      class="socialIcon"
                      :src="require('../../assets/BottomNav/Instagram.png')"
                      alt
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="warningSection">
        <div class="container">
          <p class="footerTitle">Perhatian</p>
          <div class="warningContent">
            Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan
            kesepakatan perdata antara Pemberi Pinjaman dengan Penerima
            Pinjaman, sehingga segala risiko yang timbul dari kesepakatan
            tersebut ditanggung sepenuhnya oleh masing-masing pihak.
          </div>
          <div class="warningContent">
            Risiko kredit atau gagal bayar ditanggung sepenuhnya oleh Pemberi
            Pinjaman. Tidak ada lembaga atau otoritas negara yang bertanggung
            jawab atas risiko gagal bayar ini.
          </div>
          <div class="warningContent">
            Penyelenggara dengan persetujuan dari masing-masing Pengguna
            (Pemberi Pinjaman dan/atau Penerima Pinjaman) mengakses, memperoleh,
            menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna
            (Pemanfaatan Data) pada atau di dalam benda, perangkat elektronik
            (termasuk smartphone atau telepon seluler), perangkat keras
            (hardware) maupun lunak (software), dokumen elektronik, aplikasi
            atau sistem elektronik milik Pengguna atau yang dikuasai Pengguna,
            dengan memberitahukan tujuan, Batasan dan mekanisme Pemanfaatan Data
            tersebut kepada Pengguna atau yang dikuasai Pengguna, dengan
            memberitahukan tujuan, Batasan dan mekanisme Pemanfaatan Data
            tersebut kepada Pengguna yang bersangkutan sebelum memperoleh
            persetujuan yang dimaksud.
          </div>
          <div class="warningContent">
            Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman
            pinjam meminjam, disarankan untuk tidak menggunakan layanan ini.
          </div>
          <div class="warningContent">
            Setiap kecurangan tercatat secara digital di dunia maya dan dapat
            diketahui masyarakat luas di media sosial.
          </div>
          <div class="warningContent">
            Pengguna harus membaca dan memahami informasi ini sebelum membuat
            keputusan menjadi Pemberi Pinjaman atau Penerima Pinjaman.
          </div>
          <div class="warningContent">
            Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan, tidak
            bertanggung jawab atas setiap pelanggaran atau ketidakpatuhan oleh
            Pengguna, baik Pemberi Pinjaman maupun Penerima Pinjaman (baik
            karena kesengajaan atau kelalaian Pengguna) terhadap ketentuan
            peraturan perundang-undangan maupun kesepakatan atau perikatan
            antara Penyelenggara dengan Pemberi Pinjaman dan/atau Penerima
            Pinjaman.
          </div>
          <div class="warningContent">
            Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan
            kesepakatan mengenai pinjam meminjam antara atau yang melibatkan
            Penyelenggara, Pemberi Pinjaman, dan/atau Penerima Pinjaman wajib
            dilakukan melalu escrow account dan virtual account sebagaimana yang
            diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan Nomor
            77/POJK 01/2016 tentang Layanan Pinjam Meminjam Uang Berbasis
            Teknologi Informasi dan pelanggaran atau ketidakpatuhan terhadap
            ketentuan tersebut merupakan bukti telah terjadinya pelanggaran
            hukum oleh Penyelenggara sehingga Penyelenggara wajib menanggung
            ganti rugi yang diderita oleh masing-masing Pengguna sebagai akibat
            langsung dari pelanggaran hukum tersebut di atas tanpa mengurangi
            hak Pengguna yang menderita kerugian menurut Kitab Undang Undang
            Hukum Perdata
          </div>
        </div>
      </div>
      <!-- <div class="contaner-fluid p-5-hor">
        <hr />
      </div> -->
    </div>

    <v-footer color="#252525" padless>
      <v-row justify="center" no-gutters>
        <v-col class="py-4 text-center copyrightText" cols="12">
          Copyright © PT Indo Fin Tek 2016 - 2021
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bottomNav: "home",
      widthNow: window.innerWidth,
    };
  },
  methods: {
    goToPage(str) {
      this.$router.push("/" + str);
    },
    goToBlog() {
      window.location = "https://blog.dompetkilat.co.id/";
    },
  },
};
</script>

<style>
.address {
  max-width: 361px;
  flex-grow: 0;
  margin: 18px 0 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.address-1 {
  max-width: 361px;
  flex-grow: 0;
  margin: 18px 0 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  font-weight: normal;
}
.footerTitle {
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.menuLink {
  flex-grow: 0;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  cursor: pointer;
}
.socialIcon {
  max-width: 32px;
  max-height: 32px;
  flex-grow: 0;
  object-fit: contain;
}
.warningSection {
  margin-top: -3rem;
}
.warningContainerTwo {
  margin-left: 5rem;
}
.warningContent {
  margin-bottom: 1.5rem;
  flex-grow: 0;
  font-family: Inter;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8e;
}
.copyrightText {
  flex-grow: 0;
  font-family: Inter;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.afpiContainer {
  margin-top: 1rem;
}
.afpiLogo {
  max-width: 102px;
  max-height: 31px;
}
.ojkLogo {
  max-width: 120px;
  max-height: 52px;
}
.footerContent {
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
