<template>
  <HomePageDesktop v-if="widthNowVal(widthNow) >= 550" />
  <HomePageMobile v-else />
</template>

<script>
import HomePageDesktop from "../components/homepage/homepage-desktop";
import HomePageMobile from "../components/homepage/homepage-mobile";

export default {
  name: "Home",

  components: {
    HomePageDesktop,
    HomePageMobile,
  },

  created() {
    if (window.innerWidth >= 550) {
      window.addEventListener("resize", this.myEventHandler);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.heightNow = window.innerHeight + "px";
      this.widthNow = window.innerWidth + "px";
    },
    widthNowVal(width) {
      return Number(width.substring(0, width.length - 2));
    },
  },

  data: () => ({
    heightNow: window.innerHeight + "px",
    widthNow: window.innerWidth + "px",
  }),
};
</script>
