<template>
  <v-layout align-center column style="margin-top: 100px">
    <p class="Section-Title">Panduan Pendaftaran</p>
    <v-row justify="center">
      <v-col>
        <v-row class="pt-8">
          <span class="Ellipse">
            <v-img
              src="../../assets/Panduan/monitor.png"
              height="25"
              width="30"
              class="mx-auto mt-3"
            />
          </span>
          <span style="width: 80%">
            <p class="Detail">Step 1</p>
            <p class="SubDetail">
              Buka website
              <strong class="redLink" @click="toGaji"
                >gaji.dompetkilat.co.id</strong
              >, lalu Isi formulir dengan informasi yang benar.
            </p>
          </span>
        </v-row>
        <v-row class="pt-8">
          <span class="Ellipse">
            <v-img
              src="../../assets/Panduan/smartphone.png"
              height="25"
              width="30"
              class="mx-auto mt-3"
            />
          </span>
          <span style="width: 80%;">
            <p class="Detail">
              Step 2
            </p>
            <p class="SubDetail">
              Download aplikasi Dompet Kilat dan masukkan username dan password
              yang kami kirimkan ke email kamu.
            </p>
          </span>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="pt-8">
          <span class="Ellipse">
            <v-img
              src="../../assets/Panduan/shield.png"
              height="25"
              width="30"
              class="mx-auto mt-3"
            />
          </span>
          <span style="width: 80%;">
            <p class="Detail">
              Step 3
            </p>
            <p class="SubDetail">
              Lakukan verifikasi data diri dengan foto selfie. foto KTP dan
              tanda tangan.
            </p>
          </span>
        </v-row>
        <v-row class="pt-8">
          <span class="Ellipse">
            <v-img
              src="../../assets/Panduan/money.png"
              height="25"
              width="35"
              class="mx-auto mt-3"
            />
          </span>
          <span style="width: 80%;">
            <p class="Detail">
              Step 4
            </p>
            <p class="SubDetail">
              Setelah itu, pilih produk Gaji Kilat dan masukkan nominal pinjaman
              dan tenornya. Voila! Selesai!
            </p>
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      widthNow: window.innerWidth,
    };
  },

  methods: {
    toGaji() {
      window.location = "http://gaji.dompetkilat.co.id/";
    },
  },
};
</script>

<style scoped>
.Section-Title {
  width: 271px;
  height: 30px;
  flex-grow: 0;
  margin: 0px auto 24px auto;
  font-family: Sora;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}
.Shape {
  width: 285px;
  height: 160px;
  flex-grow: 0;
  padding-left: 1%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
  border: solid 2px #d3352c;
  background-color: #ffffff;
}
.Ellipse {
  width: 60px;
  height: 60px;
  flex-grow: 0;
  margin: 0 auto 8px auto;
  padding: 6px 10px 0px;
  border-radius: 80%;
  background-color: #d3352c;
}
.Value {
  flex-grow: 0;
  font-family: Sora;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.Detail {
  flex-grow: 0;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.SubDetail {
  max-width: 500px;
  flex-grow: 0;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
.redLink {
  color: #d3352c;
  cursor: pointer;
}
@media screen and (max-width: 1250px) {
  .SubDetail {
    max-width: 400px;
    flex-grow: 0;
    margin: 8px 0 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
}
@media screen and (max-width: 1024px) {
  .SubDetail {
    max-width: 350px;
    flex-grow: 0;
    margin: 8px 0 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
}
</style>
