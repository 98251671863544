<template>
  <v-app v-bind:style="{ background: 'white', width: widthNow }">
    <TopToolbarDesktop
      v-if="widthNowVal(widthNow) >= 550"
      v-bind:style="{ background: 'white', width: widthNow }"
    ></TopToolbarDesktop>
    <TopToolbarMobile v-else />

    <!-- <PopUpLogin />
    <router-view />-->
    <v-content class="no-x-overflow">
      <router-view></router-view>
    </v-content>
    <BottomNavMobile
      v-if="widthNowVal(widthNow) <= 550"
      style="border-top: 2px solid #d3352c !important"
    />
    <BottomNavDesktop v-else style="border-top: 2px solid #d3352c !important" />
  </v-app>
</template>

<script>
import TopToolbarDesktop from "./components/toptoolbar/toptoolbar-desktop";
import TopToolbarMobile from "./components/toptoolbar/toptoolbar-mobile";
import BottomNavDesktop from "./components/bottomnav/bottomnav-desktop";
import BottomNavMobile from "./components/bottomnav/bottomnav-mobile";

export default {
  name: "App",

  components: {
    TopToolbarDesktop,
    TopToolbarMobile,
    BottomNavDesktop,
    BottomNavMobile,
  },

  created() {
    if (window.innerWidth >= 550) {
      window.addEventListener("resize", this.myEventHandler);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.heightNow = window.innerHeight + "px";
      this.widthNow = window.innerWidth + "px";
    },
    widthNowVal(width) {
      return Number(width.substring(0, width.length - 2));
    },
  },

  data: () => ({
    //
    heightNow: window.innerHeight + "px",
    widthNow: window.innerWidth + "px",
  }),
};
</script>

<style scoped>
a.bar.router-link-exact-active {
  color: #f5ca4a;
  text-decoration: none;
}
a.bar {
  color: black;
  text-decoration: none;
}
.no-x-overflow {
  overflow: hidden;
}
</style>
