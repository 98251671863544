<template>
  <v-layout align-center column style="margin-top: 100px;">
    <p class="Section-Title">Kenapa Harus Dompet Kilat</p>
    <v-row justify="center">
      <v-col class="Transparant-Box">
        <v-skeleton-loader
          height="80"
          type="image"
          v-if="!isImgLoaded"
        ></v-skeleton-loader>
        <img
          src="../../assets/HomePage/mudah.png"
          @load="onImgLoad"
          class="Cepat"
        />
        <p class="Title">Mudah</p>
        <p class="Desc">
          Semua transaksi bisa dilakukan secara online lewat aplikasi.
        </p>
      </v-col>
      <v-col class="Transparant-Box">
        <v-skeleton-loader
          height="80"
          type="image"
          v-if="!isImgLoaded"
        ></v-skeleton-loader>
        <img
          src="../../assets/HomePage/cerdas.png"
          @load="onImgLoad"
          class="keuntungan"
        />
        <p class="Title">Cerdas</p>
        <p class="Desc">
          Pembayaran otomatis dipotong dari gaji setiap bulannya.
        </p>
      </v-col>

      <v-col class="Transparant-Box">
        <v-skeleton-loader
          height="80"
          type="image"
          v-if="!isImgLoaded"
        ></v-skeleton-loader>
        <img
          src="../../assets/HomePage/aman.png"
          @load="onImgLoad"
          class="sosial"
        />
        <p class="Title">Aman</p>
        <p class="Desc Desc-sosial">
          Memiliki sertifikasi ISO:27001 yang menjamin keamanan privasi data.
        </p>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      isImgLoaded: false,
    };
  },

  created() {
    if (window.innerWidth >= 500) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },

  methods: {
    onImgLoad() {
      this.isImgLoaded = true;
    },
  },
};
</script>

<style scoped>
.Section-Title {
  width: 494px;
  height: 30px;
  flex-grow: 0;
  margin: 0px 68px 60px;
  font-family: Sora;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}
.Title {
  width: 288px;
  height: 20px;
  flex-grow: 0;
  /* margin: 12px 114px 10px 120px; */
  font-family: Sora;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}
.Desc {
  width: 288px;
  height: 44px;
  flex-grow: 0;
  /* margin: 10px 0 0; */
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.Cepat {
  width: 118px;
  /* height: 80px; */
  align-self: center;
  flex-grow: 0;
  margin: 40px 82px 0px 82px;
  object-fit: contain;
}
.keuntungan {
  width: 118px;
  /* height: 80px; */
  align-self: center;
  flex-grow: 0;
  margin: 40px 82px 0px 82px;
  object-fit: contain;
}
.Pelopor {
  width: 118px;
  /* height: 80px; */
  align-self: center;
  flex-grow: 0;
  margin: 40px 82px 0px 82px;
  object-fit: contain;
}
.sosial {
  width: 118px;
  height: 118px;
  align-self: center;
  flex-grow: 0;
  margin: 40px 82px 0px 82px;
  object-fit: contain;
}
.Transparant-Box {
  width: 285px;
  /* height: 166px; */
  flex-grow: 0;
  margin: auto 10px auto 10px;
}

.Transparant-Box-Mobile {
  width: 285px;
  height: 166px;
  flex-grow: 0;
  margin: 20px 10px 40px 10px;
}
</style>
