<template>
  <div>
    <v-navigation-drawer
      clipped
      v-model="sidebar"
      app
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }"
    >
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title>
              <div @click="$router.push('/')">
                <span class="navText">
                  Home
                </span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      app
      style="border-top: 2px solid #d3352c !important"
    >
      <v-app-bar-nav-icon @click="sidebar = !sidebar">
        <v-icon v-if="sidebar">mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-items style="padding-top: 1.25rem;">
        <span class="pl-3" @click="$router.push('/')">
          <img
            class="dkLogoMobile"
            :src="require('../../assets/dompetkilat.png')"
            alt="DK Logo"
          />
        </span>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    sidebar: false,
    clicked: "",
  }),
  methods: {},
};
</script>

<style>
.navTextActive {
  text-decoration: none;
  color: #252525;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
}
.navText {
  text-decoration: none;
  color: #767676;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
}
.dkLogoMobile {
  width: 40%;
  margin-top: -2px;
}
</style>
