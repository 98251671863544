var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.widthNow >= 1480)?_c('v-carousel',{attrs:{"show-arrows":false,"delimiter-icon":"mdi-square","hide-delimiters":"","light":"","height":_vm.heightCarousel,"deli":"","hide-delimiter-background":""}},_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i},[_c('div',{staticClass:"Form-Hero",style:({
            'margin-left': _vm.marginLeft,
            width: _vm.width,
            'margin-top': _vm.marginTop,
          })},[_c('v-card',{staticClass:"pa-8 registForm",attrs:{"elevation":"0"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.enterData($event)}}},[_c('div',{staticClass:"registTitle",style:({
                  'font-size': _vm.registFontTitle,
                })},[_vm._v(" Formulir Pendaftaran ")]),_c('div',{staticClass:"registSubtitle",style:({
                  'font-size': _vm.registFontSubtitle,
                })},[_vm._v(" Silahkan masukkan data diri dengan benar untuk melanjutkan proses pendaftaran ")]),_c('v-divider',{staticClass:"divider"}),_c('div',{staticClass:"registLabel",style:({
                  'font-size': _vm.registFontLabel,
                })},[_vm._v(" Nomor KTP"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.ktp),callback:function ($$v) {_vm.$set(_vm.registForm, "ktp", $$v)},expression:"registForm.ktp"}}),_c('div',{staticClass:"registLabel",style:({
                  'font-size': _vm.registFontLabel,
                })},[_vm._v(" Alamat Email"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.email),callback:function ($$v) {_vm.$set(_vm.registForm, "email", $$v)},expression:"registForm.email"}}),_c('div',{staticClass:"registLabel",style:({
                  'font-size': _vm.registFontLabel,
                })},[_vm._v(" Nomor Ponsel"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.hp),callback:function ($$v) {_vm.$set(_vm.registForm, "hp", $$v)},expression:"registForm.hp"}}),_c('v-btn',{staticClass:"mx-auto registButton",attrs:{"type":"submit"}},[_vm._v("Daftar Sekarang!")])],1)],1)],1),_c('div',{staticClass:"Text-Hero",style:({
            'font-size': _vm.fontMain,
            'margin-left': _vm.marginLeftMain,
            width: _vm.width,
            'margin-top': _vm.marginTopMain,
          })},[_c('p',[_vm._v("Pinjaman Aman bersama Dompet Kilat!")]),_c('p',{staticClass:"Sub-Hero",style:({
              'font-size': _vm.fontBannerSub,
              'margin-top': _vm.marginTopSub,
            })},[_vm._v(" Ajukan pinjaman sampai dengan "),_c('strong',{staticClass:"redLink"},[_vm._v("Rp 10 Juta")]),_vm._v(" untuk memenuhi berbagai kebutuhanmu! ")])]),_c('img',{staticClass:"Hero",attrs:{"src":require("../../assets/HomePage/Hero-Image.webp")}})])}),1):_vm._e(),(_vm.widthNow <= 1480)?_c('v-img',{attrs:{"src":require("../../assets/HomePage/Hero-Image.webp")}},[_c('v-layout',{staticStyle:{"margin-top":"100px"},attrs:{"align-center":"","column":""}},[_c('p',{staticClass:"Section-Title",staticStyle:{"margin-top":"100px"}},[_vm._v(" Pinjaman Aman bersama Dompet Kilat! ")]),_c('p',{staticStyle:{"margin-top":"20px","width":"328px","height":"36px"}},[_vm._v(" Ajukan pinjaman sampai dengan "),_c('strong',{staticClass:"redLink"},[_vm._v("Rp 10 Juta")]),_vm._v(" untuk memenuhi berbagai kebutuhanmu! ")]),(_vm.widthNow <= 1480)?_c('v-card',{staticClass:"pa-8 registForm",attrs:{"elevation":"0"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.enterData($event)}}},[_c('div',{staticClass:"registTitle",style:({
                'font-size': _vm.registFontTitle,
              })},[_vm._v(" Formulir Pendaftaran ")]),_c('div',{staticClass:"registSubtitle",style:({
                'font-size': _vm.registFontSubtitle,
              })},[_vm._v(" Silahkan masukkan data diri dengan benar untuk melanjutkan proses pendaftaran ")]),_c('v-divider',{staticClass:"divider"}),_c('div',{staticClass:"registLabel",style:({
                'font-size': _vm.registFontLabel,
              })},[_vm._v(" Nomor KTP"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.ktp),callback:function ($$v) {_vm.$set(_vm.registForm, "ktp", $$v)},expression:"registForm.ktp"}}),_c('div',{staticClass:"registLabel",style:({
                'font-size': _vm.registFontLabel,
              })},[_vm._v(" Alamat Email"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.email),callback:function ($$v) {_vm.$set(_vm.registForm, "email", $$v)},expression:"registForm.email"}}),_c('div',{staticClass:"registLabel",style:({
                'font-size': _vm.registFontLabel,
              })},[_vm._v(" Nomor Ponsel"),_c('strong',{staticClass:"redLink"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"registInput",attrs:{"outlined":"","dense":""},model:{value:(_vm.registForm.hp),callback:function ($$v) {_vm.$set(_vm.registForm, "hp", $$v)},expression:"registForm.hp"}}),_c('v-btn',{staticClass:"mx-auto registButton",attrs:{"type":"submit"}},[_vm._v("Daftar Sekarang!")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('KeuntunganDesktop'),_c('PanduanDesktop'),_c('SyaratKetentuanDesktop',{staticClass:"sk-margin"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }